import React from 'react';

function HomePage() {
  return (
    <div>
      <h2>Página de Inicio</h2>
      <p>Bienvenido a nuestra aplicación.</p>
    </div>
  );
}

export default HomePage;
