import React, { useState, useEffect } from 'react';
import { storage } from '../firebase';

function GalleryPage() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Aquí puedes agregar la lógica para obtener las imágenes desde Firebase Storage
    // y actualizar el estado "images" con las imágenes recuperadas.
  }, []);

  return (
    <div>
      <h2>Galería de Imágenes</h2>
      {images.length > 0 ? (
        <div className="image-gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt={`Imagen ${index}`}
              width="200"
              height="200"
            />
          ))}
        </div>
      ) : (
        <p>No hay imágenes disponibles.</p>
      )}
    </div>
  );
}

export default GalleryPage;
