import React,{useEffect, useState} from 'react';
import { auth, firestore } from '../firebase';
import { collection, doc, getDocs, getDoc } from "firebase/firestore";

function ProfilePage() {
  const user = auth.currentUser;
  const [placesData, setPlacesData] = useState([]);
  const [featuredPlacesData, setFeaturedPlacesData] = useState([]);
//   const fetchPlacesData = async () => {
//     try {
//       const placesCollection = await firestore.collection('PLACES').doc('bar').collection('Distrito Nacional').get();
//       const placesDataArray = placesCollection.docs.map(doc => doc.data());
//       setPlacesData(placesDataArray);

//       const featuredPlacesCollection = await firestore.collection('PLACES').doc('bar').collection('Distrito Nacional').where('FEATURED', '==', true).get();
//       const featuredPlacesDataArray = featuredPlacesCollection.docs.map(doc => doc.data());
//       setFeaturedPlacesData(featuredPlacesDataArray);

//       console.log(featuredPlacesData);
      
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };


// useEffect(() => {
//   fetchPlacesData();
// }, []);
// useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const usersCollection = firestore.collection('PLACES').doc('bar').collection('Distrito Nacional');
//         const usersSnapshot = await usersCollection.get();
//         const usersData = usersSnapshot.docs.map(doc => doc.data());
//         setPlacesData(usersData);
//       } catch (error) {
//         console.error('Error al obtener usuarios:', error);
//       }
//     };

//     fetchUsers();
//   }, []);
  const fetchPost = async () => {
       
    //await getDocs(collection(firestore, "PLACES"))
        // .then((querySnapshot)=>{               
        //     const newData = querySnapshot.docs.map(doc => doc.data());
        //     setPlacesData(newData);                
        //     console.log('listado 2', newData);
        // })

        // LA FORMA QUE FUNCIONA
        const querySnapshot = await getDocs(collection(firestore, 'users'));
        querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        });
        // LA FORMA QUE FUNCIONA
        //Para traer un documento especifico
        //const d = await getDoc(doc(firestore(), collection_name, id))
   
}

useEffect(()=>{
    fetchPost();
}, [])
console.log('listado', placesData);
  return (
    <div>
      <h2>Perfil de Usuario</h2>
      {user ? (
        <>
          <p>Nombre de usuario: {user.displayName}</p>
          <p>Correo electrónico: {user.email}</p>
          <p>ID de usuario: {user.uid}</p>
        </>
      ) : (
        <p>No has iniciado sesión.</p>
      )}
    </div>
  );
}

export default ProfilePage;
