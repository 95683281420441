// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route,Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProfilePage from './components/ProfilePage';
import GalleryPage from './components/GalleryPage';

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/perfil">Perfil</Link>
            </li>
            <li>
              <Link to="/galeria">Galería de Imágenes</Link>
            </li>
          </ul>
        </nav>

        <Routes>
        <Route path="/perfil" element={<ProfilePage />} />
        <Route path="/galeria" element={<GalleryPage />} />
        <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
