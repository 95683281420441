//import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"

// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDHJR9fc_PHmLShscXUnIYkMrhZ_uazVDE",
    authDomain: "lugaresrd-6f664.firebaseapp.com",
    projectId: "lugaresrd-6f664",
    storageBucket: "lugaresrd-6f664.appspot.com",
    messagingSenderId: "566224364668",
    appId: "1:566224364668:web:ce810481b6912a36c215f9",
    measurementId: "G-JKLVJ9L8R4"
};


const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export { firestore };
export default firebaseApp;